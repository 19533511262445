/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import HeaderLayout from "../../layouts/admin/HeaderLayout";
import FooterLayout from "../../layouts/admin/FooterLayout";
import Listfilter from "../../components/ListFilter";
import MaterialDataGrid from "../../components/DataTable";
import { axiosInstance } from "../../utils/commonInstance";
import useDataFetching from "../../hooks/useDataFatching";
import { Edit, Eye, MoreHorizontal } from "feather-icons-react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { showToast } from "../../hooks/showToast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Multiselect from "multiselect-react-dropdown";

const fetchDataForPlatform = async (params, endpoint) => {
	return await axiosInstance.get(`web/${endpoint}`, { params });
};

const ListPage = ({
	title,
	ComonComponanatList,
	dynamiColumns,
	endPoint,
	filterOption,
	columnOrder,
}) => {
	const {
		state,
		columnFilters,
		setColumnFilters,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		manualFetchData,
	} = useDataFetching(fetchDataForPlatform, endPoint);
	const location = useLocation();
	const [roleRightsData, setRoleRightsData] = useState([]);
	const [locationOption, setLocationOption] = useState([]);
	const [checkpointOption, setCheckpointOption] = useState([]);
	const [getShiftOption, setGetShiftOption] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	//   const { setValue } = useForm({});
	const userSchema = yup.object().shape({
		checkpoint_id: yup
			.array()
			.of(yup.string())
			.required("Please Select Checkpoint")
			.min(1, "Please Select Checkpoint"),
		shift_id: yup.string().required("Please Select Shift"),
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver(userSchema),
	});

	const onSubmit = async (data) => {
		const params = {
			username: data?.username,
			checkpoint_id: data?.checkpoint_id,
			shift_id: data?.shift_id,
		};

		console.log(params);
		debugger;
		// const endPont = isEdit ? `edituser/${previousData?.id}` : "adduser";
		try {
			const response = await axiosInstance.post(
				`web/editusersdirect/${previousData?.id}`,
				{
					...params,
					mode_of_login: "Web",
				}
			);

			//   if (response?.data?.statusCode !== 200) {
			if (response?.data?.error) {
				showToast(response?.data?.message, "error");
				return false;
			}
			manualFetchData();
			showToast(response?.data?.message, "success");
			handleClosePartialEdit();
		} catch (error) {
			showToast(error?.data?.message, "error");
		}
	};

	const [calculations, setCalculations] = useState(0);
	const [isView, setIsView] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [previousData, setPreviousData] = useState({});
	const [modalPreview, setModalPreview] = useState({
		isOpen: false,
		headerContent: "",
		subHeaderContent: "",
		data: {},
		clicked: null,
		text: "",
	});
	const [modalPartialEdit, setModalPartialEdit] = useState({
		isOpen: false,
		headerContent: "",
		subHeaderContent: "",
		data: {},
		clicked: null,
		text: "",
	});

	const roleId = localStorage.getItem("role_id");

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await axiosInstance.get(
					`web/getrolerightsdata/${roleId}`
				);
				const rightsData = response?.data?.data || [];
				// alert(rightsData);
				// console.log(rightsData);
				setRoleRightsData(rightsData);
				rightsData.forEach((right) => {
					setValue(right, true);
				});
			} catch (error) {
				showToast("Error fetching role rights data", "error");
			}
		}
		if (roleId) {
			fetchData();
		}
	}, [roleId, setValue]);

	const pagePermissions = {
		"/userlist": ["user.edit"],
		"/companymasterlist": ["company.edit"],
		"/locationList": ["location.edit"],
		"/companylist": ["companylocation.edit"],
		"/vendorlist": ["vendor.edit"],
		"/shiftlist": ["shift.edit"],
		"/designationlist": ["designation.edit"],
		"/checkpointlist": ["checkpoint.edit"],
		"/roleList": ["role.edit"],
	};
	const checkPermission = (roleRightsData, permission) =>
		!roleRightsData.includes(permission);
	const currentPath = location.pathname;
	const requiredPermissions = pagePermissions[currentPath] || [];

	useEffect(() => {
		setIsView(false);
		setPreviousData({});
		setIsEdit(false);
		setFormState(false);
	}, [title]);

	const handleViewClick = (row) => {
		setIsView(true);
		setPreviousData(row);
		setIsEdit(false);
		setFormState(true);
		// Add your view logic here
	};

	const handleEditClick = (row) => {
		setIsEdit(true);
		setIsView(false);
		setPreviousData(row);
		setFormState(true);
		// Add your edit logic here
	};

	const handlePartialEditClick = (row) => {
		setModalPartialEdit((prevData) => ({
			...prevData,
			isOpen: true,
			header: "Check Point & Shift Allocation",
		}));
		setIsEdit(true);
		setIsView(false);
		setPreviousData(row);
		// setFormState(true);
		// Add your edit logic here
	};
	const handlePreviewClick = (row) => {
		setModalPreview((prevData) => ({
			...prevData,
			isOpen: true,
			header: "Document Preview",
			data: row,
		}));
		// Add your edit logic here
	};

	const handleClosePartialEdit = (row) => {
		setModalPartialEdit((prevData) => ({
			...prevData,
			isOpen: false,
			header: "",
			data: {},
		}));
		setPreviousData(row);
	};
	const handleClosePreview = () => {
		setModalPreview((prevData) => ({
			...prevData,
			isOpen: false,
			header: "",
			data: {},
		}));
	};
	useEffect(() => {
		// Assuming OpenNewPagination is an object with properties pageIndex and pageSize
		const calculation = pagination.pageIndex * pagination.pageSize;
		// Set test to 0 if OpenNewPagination.pageIndex is 0, otherwise use the calculation
		setCalculations(calculation);
	}, [pagination.pageIndex, pagination.pageSize]);

	const dynamicColumns = useMemo(() => {
		const columns = [];
		// Add dynamic columns
		columns.push({
			header: "Sr. No.",
			Cell: ({ row }) => {
				const number = calculations + (row?.index + 1);
				const formattedNumber = number < 10 ? `0${number}` : number;
				return <div className="sr-no">{formattedNumber}</div>;
			},
			maxSize: 15,
			minSize: 10,
		});

		dynamiColumns?.forEach((col) => {
			if (col.accessorKey === "action") {
				// Customize the Cell renderer for the actions column
				columns.push({
					...col,
					Cell: ({ row }) => (
						<div style={{ display: "flex" }}>
							<>
								<div
									onClick={() => handleViewClick(row?.original)}
									style={{ marginRight: "5px", cursor: "pointer" }}
								>
									<Eye />
								</div>
								<button
									className="btn p-0 m-0 ms-4"
									onClick={() => handleEditClick(row?.original)}
									style={{ cursor: "pointer" }}
									disabled={requiredPermissions.some((permission) =>
										checkPermission(roleRightsData, permission)
									)}
								>
									<Edit style={{ height: "18px", width: "18px" }} />
								</button>
								{row?.original?.role_name === "Mobile User" ? (
									<button
										className="btn p-0 m-0 ms-4"
										onClick={() => handlePartialEditClick(row?.original)}
										style={{
											cursor: "pointer",
											border: "1px solid #000000de",
											borderRadius: "50%",
											padding: "5px",
										}}
									>
										<MoreHorizontal
											style={{ height: "18px", width: "18px", margin: "2px" }}
										/>
									</button>
								) : null}
							</>
						</div>
					),
				});
			} else if (col.accessorKey === "preview") {
				// Customize the Cell renderer for the actions column
				columns.push({
					...col,
					Cell: ({ row }) => (
						<div onClick={() => handlePreviewClick(row?.original)}>
							<a role="button" className="text-decoration-underline">
								Preview
							</a>
						</div>
					),
				});
			} else {
				columns.push(col);
			}
		});
		return columns;
	}, [dynamiColumns, calculations, requiredPermissions, roleRightsData]);

	useEffect(() => {
		setFormState(false);
	}, [endPoint]);

	const [formState, setFormState] = useState(false);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "max-content",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		'@media (max-width: 600px)': {
			width: '96%',
		}
	};

	const handleBackButton = () => {
		setIsView(false);
		setIsEdit(false);
		setFormState(false);
		setPreviousData({});
	};

	useEffect(() => {
		async function setFiles() {
			if (isView || isEdit) {
				setValue("username", previousData?.username);
				setSelectedOptions(previousData?.checkpoints);
				setValue("checkpoint_id", previousData?.checkpoints_id);
				setValue("shift_id", previousData?.shift_id);
			}
		}
		setFiles();
	}, [isEdit, isView, previousData, setValue]);

	const formattedOptions = checkpointOption?.map((ele) => ({
		id: ele?.id,
		name: ele?.checkpoint_name,
	}));

	const handleSelect = (selectedList, selectedItem) => {
		setSelectedOptions(selectedList);
		setValue(
			"checkpoint_id",
			selectedList.map((item) => item.id)
		);
	};

	const handleRemove = (selectedList, removedItem) => {
		setSelectedOptions(selectedList);
		setValue(
			"checkpoint_id",
			selectedList.map((item) => item.id)
		);
	};

	const selectedCompanyId = previousData?.company_id;
	const selectedLocationId = previousData?.location_id;
	useEffect(() => {
		if (selectedCompanyId) {
			async function fetchLocations() {
				const response = await axiosInstance.get(
					`web/locationdata/${selectedCompanyId}`
				);
				if (!response?.data?.error) {
					setLocationOption(response?.data?.data);
				} else {
					setLocationOption(response?.data?.data);

					//   showToast(response?.data?.message, "error");
				}
			}
			fetchLocations();
		}

		if (selectedCompanyId && selectedLocationId) {
			async function fetchLocations() {
				const response = await axiosInstance.get(
					`web/checkpointdata?companyId=${selectedCompanyId}&locationId=${selectedLocationId}`
				);
				if (!response?.data?.error) {
					setCheckpointOption(response?.data?.data);
				} else {
					setCheckpointOption(response?.data?.data);
					//   showToast(response?.data?.message, "error");
				}
			}
			fetchLocations();
		}

		if (selectedCompanyId && selectedLocationId) {
			async function fetchLocations() {
				const response = await axiosInstance.get(
					`web/shiftdata?companyId=${selectedCompanyId}&locationId=${selectedLocationId}`
				);
				if (!response?.data?.error) {
					setGetShiftOption(response?.data?.data);
				} else {
					setGetShiftOption(response?.data?.data);
					//   showToast(response?.data?.message, "error");
				}
			}
			fetchLocations();
		}
	}, [selectedCompanyId, selectedLocationId]);
	const wrapStyle = `
    .multiSelectContainer .searchWrapper span.chip {
      white-space: wrap; 
    }
  `;

	useEffect(() => {
		setPagination({ pageIndex: 0, pageSize: 10 });
	}, [endPoint, title, globalFilter, setPagination]);

	return (
		<>
			<div className="main">
				<HeaderLayout title={title} />
				{!formState ? (
					<section className="user-listmain content web">
						<>
							<Listfilter
								toggleFormFillrter={setFormState}
								setGlobalFilter={setGlobalFilter}
								filterOption={filterOption}
								title={title}
								data={[]}
							/>
							<MaterialDataGrid
								columns={dynamicColumns}
								data={state?.data}
								rowCount={state?.rowCount}
								isError={state?.isError}
								handleColumnFilter={setColumnFilters}
								handlePagination={setPagination}
								initialState={{
									columnFilters: columnFilters,
									isLoading: state?.isLoading,
									pagination: pagination,
									showAlertBanner: state?.isError,
									showProgressBars: state?.isRefetching,
									columnOrder: columnOrder ? columnOrder : [],
								}}
							/>
						</>
					</section>
				) : (
					<ComonComponanatList
						toggleFormFillrter={handleBackButton}
						manualFetchData={manualFetchData}
						setGlobalFilter={setGlobalFilter}
						isView={isView}
						isEdit={isEdit}
						previousData={previousData}
					/>
				)}
				{/* {formState && <ComonComponanatList toggleFormFillrter={setFormState} />} */}
				<FooterLayout />

				<Modal
					open={modalPreview?.isOpen}
					onClose={handleClosePreview}
					aria-labelledby="modal-modal-title-preview"
					aria-describedby="modal-modal-description-preview"
				>
					<Box sx={{ position: "elative", overflow: "hidden" }}>
						<Box sx={style}>
							<IconButton
								sx={{ position: "absolute", top: 8, right: 8 }}
								onClick={handleClosePreview}
							>
								<Close />
							</IconButton>
							<Typography
								id="modal-modal-title-preview"
								variant="h6"
								component="h2"
							>
								{modalPreview?.header}
							</Typography>
							<Typography id="modal-modal-description-preview" sx={{ mt: 2 }} className="text-break">
								{location?.pathname === "/userlist" ? (
									<>
										<label>Aadharcard File:</label> <br />
										<a
											href={modalPreview.data?.adharcard_file_url}
											target="_blank"
										>
											{modalPreview.data?.adharcard_file_url}
										</a>{" "}
										<br />
										<label>Police Verification Image:</label> <br />
										<a
											href={modalPreview.data?.police_verification_image_url}
											target="_blank"
										>
											{modalPreview.data?.police_verification_image_url}
										</a>
										<br />
										<label>Gun Licence Image:</label> <br />
										<a
											href={modalPreview.data?.gunlicence_image_url}
											target="_blank"
										>
											{modalPreview.data?.gunlicence_image_url}
										</a>
										<br />
										<label>Residance Image:</label> <br />
										<a
											href={modalPreview.data?.residence_image_url}
											target="_blank"
										>
											{modalPreview.data?.residence_image_url}
										</a>
									</>
								) : (
									<>
										<label>Agreement File:</label> <br />
										<a
											href={modalPreview.data?.agreement_file_url}
											target="_blank"
										>
											{modalPreview.data?.agreement_file_name}
										</a>
									</>
								)}
							</Typography>
						</Box>
					</Box>
				</Modal>
				<Modal
					open={modalPartialEdit?.isOpen}
					aria-labelledby="modal-modal-title-checkpoint-shift"
					aria-describedby="modal-modal-description-checkpoint-shift"
				>
					<Box sx={{ position: "elative", overflow: "hidden" }}>
						<Box sx={style} style={{ border: "none", borderRadius: "15px" }}>
							<IconButton
								sx={{ position: "absolute", top: 8, right: 8 }}
								onClick={handleClosePartialEdit}
							>
								<Close
									style={{
										color: "#fff",
										background: "#f34e4e",
										borderRadius: "5px",
									}}
								/>
							</IconButton>
							<Typography
								id="modal-modal-title-checkpoint-shift"
								variant="h6"
								component="h2"
							>
								{modalPartialEdit?.header}
							</Typography>
							<Typography
								id="modal-modal-description-checkpoint-shift"
								className="container"
								sx={{ mt: 2 }}
							>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="user-details">
										<div className="card">
											<div className="row card-body justify-content-around py-md-5 py-4 align-items-center">
												<div className="col-lg-4 col-md-4">
													<div className="form-group mb-3">
														<label htmlFor="username" className="form-label">
															Security Guard Name
															<span className="text-danger">*</span>
														</label>
														<input
															type="text"
															className="form-control py-2"
															maxLength={50}
															id="username"
															placeholder="Enter Name"
															{...register("username")}
															disabled
														/>
														{errors.username && (
															<p className="text-danger">
																{errors.username.message}
															</p>
														)}
													</div>
												</div>

												<div className="col-lg-4 col-md-4 mb-3 position-relative">
													<div className="form-group">
														<label
															htmlFor="checkpoint_id"
															className="form-label"
														>
															Assigned Checkpoint
															<span className="text-danger">*</span>
														</label>

														<Multiselect
															options={formattedOptions}
															selectedValues={selectedOptions}
															onSelect={handleSelect}
															onRemove={handleRemove}
															displayValue="name"
															placeholder={
																(selectedOptions || []).length === 0
																	? "Checkpoint"
																	: ""
															}
															{...register("checkpoint_id")}
															defaultValue=""
														/>
														{selectedOptions?.length === 0 &&
															errors.checkpoint_id && (
																<p className="text-danger position-absolute">
																	{errors.checkpoint_id.message}
																</p>
															)}

														<style>{wrapStyle}</style>
													</div>
												</div>

												<div className="col-lg-4 col-md-4 mb-3">
													<div className="form-group">
														<label htmlFor="shift_id" className="form-label">
															Assigned Shift
															<span className="text-danger">*</span>
														</label>

														<Controller
															name="shift_id"
															control={control}
															render={({ field }) => (
																<select
																	className="form-select py-2 w-100"
																	{...field}
																	defaultValue=""
																>
																	{/* <option value="" disabled>
                                    Shift
                                  </option> */}
																	{getShiftOption?.map((ele) => (
																		<option
																			value={ele?.id}
																		>{`${ele.shift_name}  - ${ele?.start_time} to ${ele?.end_time}`}</option>
																	))}
																</select>
															)}
														/>
														{errors.shift_id && (
															<p className="text-danger">
																{errors.shift_id.message}
															</p>
														)}
													</div>
												</div>

												<div className="col-12 text-center mt-lg-5 mt-4 d-sm-inline-flex justify-content-sm-center d-grid gap-3">
													<button
														className="btn btn-lg btn-primary px-5 w-100"
														type="submit"
													>
														Save
													</button>
													<button
														className="btn btn-lg mx-sm-3 btn-primary px-5 w-100"
														type="button"
														onClick={handleClosePartialEdit}
													>
														Cancel
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</Typography>
						</Box>
					</Box>
				</Modal>
			</div>
		</>
	);
};
export default ListPage;
