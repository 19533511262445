import React, { useEffect, useState, useRef } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  useMap,
  Circle,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import HeaderLayout from "../../layouts/admin/HeaderLayout";
import { Controller, useForm } from "react-hook-form";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import Multiselect from "multiselect-react-dropdown";
import "react-datepicker/dist/react-datepicker.css";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  company_id: yup.string().required("Please select company"),
  location_id: yup.string().required("Please select location"),
  users: yup
    .array()

    .required("Please select user")
    .min(1, "Please select user"),
});

const createUserIcon = (color, text) => {
  const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="${color}" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
      <circle cx="12" cy="7" r="4"></circle>
      <path d="M12 14c-4.418 0-8 3.582-8 8v2h16v-2c0-4.418-3.582-8-8-8z"></path>
      <text x="12" y="20" text-anchor="middle" stroke="#000" stroke-width="1px" dy=".3em"></text>
    </svg>
  `;
  return new L.DivIcon({
    html: svgIcon,
    className: "",
    iconSize: [18, 18],
    iconAnchor: [16, 16],
    popupAnchor: [0, -32],
  });
};

const createCheckpointIcon = (color) => {
  const svgIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="${color}" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin">
        <path d="M21 10c0-5.523-4.477-10-10-10S1 4.477 1 10c0 7 10 13 10 13s10-6 10-13z"></path>
      </svg>
    `;
  return new L.DivIcon({
    html: svgIcon,
    className: "",
    iconSize: [18, 18],
    iconAnchor: [24, 48],
    popupAnchor: [0, -48],
  });
};

const generateColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const hue = ((i * 360) / numColors) % 360;
    colors.push(`hsl(${hue}, 100%, 50%)`);
  }
  return colors;
};

const MapViewUpdater = ({ lat, lng }) => {
  const map = useMap();

  useEffect(() => {
    if (lat && lng) {
      map.setView([lat, lng], 13);
    }
  }, [lat, lng, map]);

  return null;
};

const AnimatedPolyline = ({
  checkpoint,
  user,
  userColor,
  playbackState,
  speed,
  userCount,
  resetTrigger,
}) => {
  const [positions, setPositions] = useState([]);
  const [currentMarkerPosition, setCurrentMarkerPosition] = useState(null);
  const currentIndex = useRef(0);
  const markerRef = useRef(null);
  const animationRef = useRef(null);

  const locations =
    user?.pastLocations?.map((location) => [
      location.lat,
      location.lng,
      location.date_time,
    ]) || [];

  const startAnimation = () => {
    clearInterval(animationRef.current);
    animationRef.current = setInterval(() => {
      if (currentIndex.current < locations.length) {
        const newPosition = locations[currentIndex.current];

        setPositions((prevPositions) =>
          [...prevPositions, newPosition].slice(-5)
        );
        setCurrentMarkerPosition(newPosition);
        currentIndex.current += 1;
      } else {
        clearInterval(animationRef.current);
      }
    }, 1000 / speed);
  };

  useEffect(() => {
    if (playbackState === "play") {
      startAnimation();
    } else {
      clearInterval(animationRef.current);
    }
    return () => clearInterval(animationRef.current);
  }, [playbackState, speed]);

  useEffect(() => {
    if (userCount === 1 && markerRef.current && currentMarkerPosition) {
      markerRef.current.openPopup();
    }
  }, [currentMarkerPosition]);

  useEffect(() => {
    setPositions([]);
    setCurrentMarkerPosition(null);
    currentIndex.current = 0;
  }, [resetTrigger]);

  return (
    <>
      {positions.map((pos, index) =>
        index < positions.length - 1 ? (
          <Polyline
            key={index}
            pathOptions={{
              color: `rgba(0, 0, 255, ${
                1 - (positions.length - index - 1) * 0.2
              })`,
            }}
            positions={[positions[index], positions[index + 1]]}
          />
        ) : null
      )}

      {currentMarkerPosition && (
        <Marker
          position={currentMarkerPosition}
          icon={createUserIcon(userColor)}
          ref={markerRef}
        >
          <Popup autoOpen={true}>
            <strong>{user.name}</strong> - {checkpoint.name}
            <p>
              {currentMarkerPosition[0]}, {currentMarkerPosition[1]}
              <br />
              {currentMarkerPosition[2]}
            </p>
          </Popup>
        </Marker>
      )}
    </>
  );
};

const TrackLocation = () => {
  const multiselectRef = React.createRef();
  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [userOption, setUserOption] = useState([]);
  const [PastLocationData, setPastLocationData] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [value, onChange] = useState([new Date(), new Date()]);

  const StartDate = moment(value[0]).format("YYYY-MM-DD HH:mm:ss");
  const EndDate = moment(value[1]).format("YYYY-MM-DD HH:mm:ss");

  const [tagTowerCoords] = useState([28.492802304144913, 77.08219494139554]);
  const tagTowerName = "Tag Tower, Gurugram";

  const [positions, setPositions] = useState([]);
  
  const [activeView, setActiveView] = useState("track");
  const [userCount, setUserCount] = useState(0);
  const [resetTrigger, setResetTrigger] = useState(0);  

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  useEffect(() => {
    async function fetchData() {
      const response = await axiosInstance.get(`web/companydropdown`);
      setCompanyOption(response?.data?.data);
      // ...
    }
    fetchData();
  }, []);

  const colors = generateColors(
    PastLocationData.reduce(
      (acc, checkpoint) => acc + checkpoint.users.length,
      PastLocationData.length
    )
  );

  let colorIndex = 0;

  const selectedCompanyId = watch("company_id");
  const selectedLocationId = watch("location_id");
  useEffect(() => {
    if (selectedCompanyId) {
      async function fetchLocations() {
        const response = await axiosInstance.get(
          `web/locationdata/${selectedCompanyId}`
        );
        if (!response?.data?.error) {
          setLocationOption(response?.data?.data);
        } else {
          setLocationOption([]);
          showToast(response?.data?.message, "error");
        }
      }
      fetchLocations();
    }
  }, [selectedCompanyId]);

  const onSubmit = async () => {
    setPlaybackState("pause");
    if (!value.length || !value[0] || !value[1]) {
      showToast("Please Select Date", "error");
      return;
    }

    const startDate = moment(value[0], "DD-MM-YYYY", true);
    const endDate = moment(value[1], "DD-MM-YYYY", true);

    if (!startDate.isValid() || !endDate.isValid()) {
      showToast("Invalid date format. Use DD-MM-YYYY", "error");
      return;
    }

    if (
      startDate.year().toString().length !== 4 ||
      endDate.year().toString().length !== 4
    ) {
      showToast("Year must be 4 digits (DD-MM-YYYY)", "error");
      return;
    }

    const data = {
      company_id: selectedCompanyId,
      locationId: selectedLocationId,
      user_id: selectedOptions.map((item) => item.id),
      from: StartDate,
      to: EndDate,
    };
    console.log("ttttttttt", data);
    setUserCount(data?.user_id?.length);
    const response = await axiosInstance.post(`web/pastlocation`, data);
    if (!response?.data?.error) {
      setPastLocationData(response?.data?.data);
      setResetTrigger((prev) => prev + 1);
      setPlaybackState("play");
      setSpeed(1);
    } else {
      showToast(response?.data?.message, "error");
    }
  };

  const firstCheckpoint = PastLocationData?.[0];

  const handleLocationChange = async (e) => {
    const { value } = e.target;
    const response = await axiosInstance.get(
      `web/trackinguserdata?companyId=${selectedCompanyId}&locationId=${value}`
    );
    if (!response?.data?.error) {
      const formattedOptions = response?.data?.data?.map((ele) => ({
        id: ele?.id,
        name: ele?.guard_details,
      }));

      setUserOption(formattedOptions);
    } else {
      setUserOption([]);
      showToast(response?.data?.message, "error");
    }
  };

  const handleSelect = (selectedList, selectedItem) => {
    if (selectedItem?.id === "All") {
      setSelectedOptions(userOption);
      setValue("users", userOption);
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        setSelectedOptions(newList);
        setValue("users", newList);
      } else {
        if (userOption?.length - 1 === selectedList?.length) {
          setSelectedOptions(userOption);
          setValue("users", userOption);
        } else {
          setSelectedOptions(selectedList);
          setValue("users", selectedList);
        }
      }
    }
  };

  const handleRemove = (selectedList, removedItem) => {
    if (removedItem?.id === "All") {
      setSelectedOptions([]);
      setValue("users", []);
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        setSelectedOptions(newList);
        setValue("users", newList);
      } else {
        setSelectedOptions(selectedList);
        setValue("users", selectedList);
      }
    }
  };

  const [playbackState, setPlaybackState] = useState("play");
  const [speed, setSpeed] = useState(1);  

  const handlePlay = () => setPlaybackState("play");
  const handlePause = () => setPlaybackState("pause");
  const handleSpeedChange = (e) => setSpeed(Number(e.target.value));  

  return (
    <>
      <div className="main">
        <HeaderLayout title={"Track Location"} />
        <section className="user-listmain content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-xxl-3 col-xl-2 col-md-6 col-sm-6 col-12 position-relative">
                <div className="form-group">
                  <label htmlFor="company_id">Select Company</label>

                  <Controller
                    name="company_id"
                    control={control}
                    render={({ field }) => (
                      <select
                        className="form-select py-2 w-100"
                        {...field}
                        defaultValue=""
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("location_id", "");
                          setSelectedOptions([]);
                          setValue("users", []);
                        }}
                      >
                        <option value="" disabled>
                          Company
                        </option>
                        {companyOption?.map((ele) => (
                          <option value={ele?.id}>{ele?.company_name}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors?.company_id && (
                    <p className="text-danger">{errors?.company_id?.message}</p>
                  )}
                </div>
              </div>

              <div className="col-xxl-3 col-xl-2 col-md-6 col-sm-6 col-12 position-relative">
                <div className="form-group">
                  <label htmlFor="location_id" className="form-label">
                    Select Location
                  </label>

                  <Controller
                    name="location_id"
                    control={control}
                    render={({ field }) => (
                      <select
                        className="form-select py-2 w-100"
                        {...field}
                        defaultValue=""
                        onChange={(e) => {
                          field.onChange(e);
                          handleLocationChange(e);
                          setSelectedOptions([]);
                          setValue("users", []);
                          // handleGeocode(e?.target?.selectedOptions[0]?.label)
                        }}
                      >
                        <option value="" disabled>
                          Location
                        </option>
                        {locationOption?.map((ele) => (
                          <option value={ele?.id}>{ele?.location_name}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors?.location_id && (
                    <p className="text-danger">
                      {errors?.location_id?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-6 col-12 position-relative">
                <div className="form-group">
                  <label htmlFor="users" className="form-label">
                    Security Guard Name
                  </label>

                  <Multiselect
                    ref={multiselectRef}
                    options={userOption}
                    selectedValues={selectedOptions}
                    displayValue="name"
                    placeholder={"Security Guard Name"}
                    defaultValue=""
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={true}
                    showCheckbox={true}
                    style={{
                      searchBox: {
                        height: selectedOptions?.length >= 3 ? "100px" : "40px",
                        overflowY:
                          selectedOptions?.length >= 3 ? "scroll" : "auto",
                      },
                    }}
                  />
                  {console.log("selectedOptions", selectedOptions)}

                  {selectedOptions.length === 0
                    ? errors.users && (
                        <p className="text-danger">{errors?.users?.message}</p>
                      )
                    : ""}
                </div>
              </div>

              <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-6 col-12 position-relative">
                <div className="form-group">
                  <label htmlFor="users" className="form-label">
                    Date
                  </label>
                  <br />

                  <Controller
                    name="date"
                    className="form-select py-2 w-100"
                    control={control}
                    render={({ field }) => (
                      <DateTimeRangePicker
                        onChange={(e) => {
                          const currentDate = new Date();

                          const futureDates = e.filter((date) => {
                            const selectedDate = new Date(date);
                            return selectedDate > currentDate;
                          });

                          if (futureDates.length > 0) {
                            showToast(
                              "selected dates and times are in the future!",
                              "error"
                            );
                          } else {
                            onChange(e);
                          }
                        }}
                        value={value}
                        maxDate={new Date()}
                        disableClock
                        format={"dd/MM/y HH:mm"}
                        clearIcon={null}
                      />
                    )}
                  />

                  <button
                    type="submit"
                    style={{ margin: "10px", marginLeft: "0px" }}
                    className="btn btn-lg btn-primary px-3 h-100"
                    onClick={() => setActiveView("track")}
                  >
                    Track
                  </button>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary h-100"
                    onClick={() => setActiveView("mapView")}
                  >
                    Map View
                  </button>
                </div>
              </div>

              <div style={{ zIndex: 0 }}>
                <h4 className="text-capitalize mb-2">
                  <strong>Note:</strong> click on particular location and
                  provide name
                </h4>
                {activeView === "track" && (
                  <div className="position-relative">
                    {userCount !== 0 && (
                      <>
                        <div className="play-pause-controls">
                          <button
                            onClick={handlePlay}
                            disabled={playbackState === "play"}
                          >
                            Play
                          </button>
                          <button
                            onClick={handlePause}
                            disabled={playbackState === "pause"}
                          >
                            Pause
                          </button>
                        </div>
                        <div className="user-speed-control">
                          <label>Speed : </label>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Small select example"
                            onChange={handleSpeedChange}
                            value={speed}
                          >
                            <option value={1}>1x</option>
                            <option value={2}>2x</option>
                            <option value={5}>5x</option>
                            <option value={10}>10x</option>
                            <option value={20}>20x</option>
                          </select>
                        </div>
                      </>
                    )}

                    <MapContainer
                      center={[28.471231802967917, 77.02304800613382]}
                      zoom={13}
                      scrollWheelZoom={true}
                      style={{ height: "60vh", width: "100%" }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {firstCheckpoint && (
                        <MapViewUpdater
                          lat={firstCheckpoint.lat}
                          lng={firstCheckpoint.lng}
                        />
                      )}
                      {PastLocationData?.map((checkpoint, index) => {
                        // const checkpointColor = colors[colorIndex++];
                        const checkpointColor = colors[index % colors.length];
                        return (
                          <React.Fragment key={checkpoint.id}>
                            <Marker
                              position={[checkpoint.lat, checkpoint.lng]}
                              icon={createCheckpointIcon(checkpointColor)}
                            >
                              <Popup>
                                <div>
                                  <strong>{checkpoint.name}</strong>
                                  <p>{checkpoint.details}</p>
                                </div>
                              </Popup>
                            </Marker>                            
                            {checkpoint.users.map((user, userIndex) => {
                              const userColor =
                                colors[userIndex % colors.length];                              
                              return (
                                <AnimatedPolyline
                                  // key={user.id}
                                  checkpoint={checkpoint}
                                  user={user}
                                  userColor={userColor}
                                  playbackState={playbackState}
                                  speed={speed}
                                  userCount={userCount}
                                  resetTrigger={resetTrigger}
                                />
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </MapContainer>
                  </div>
                )}

                {activeView === "mapView" && (
                  <div>
                    <MapContainer
                      center={[28.471231802967917, 77.02304800613382]}
                      zoom={13}
                      scrollWheelZoom={true}
                      style={{ height: "60vh", width: "100%" }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {firstCheckpoint && (
                        <MapViewUpdater
                          lat={firstCheckpoint.lat}
                          lng={firstCheckpoint.lng}
                        />
                      )}
                      {PastLocationData?.map((checkpoint, index) => {
                        const checkpointColor = colors[colorIndex++];
                        return (
                          <React.Fragment key={checkpoint.id}>
                            <Marker
                              position={[checkpoint.lat, checkpoint.lng]}
                              icon={createCheckpointIcon(checkpointColor)}
                              eventHandlers={{
                                click: () => { },
                              }}
                            >
                              <Popup>
                                <div>
                                  <strong>{checkpoint.name}</strong>
                                  <p>{checkpoint.details}</p>
                                </div>
                              </Popup>
                            </Marker>                            
                            {checkpoint.users.map((user, userIndex) => {
                              const userColor = colors[colorIndex++];
                              const baseSpacing = 0.0002; 
                              const offsetLat = baseSpacing * userIndex; 
                              const offsetLng =
                                baseSpacing *
                                (userIndex % 2 === 0 ? 1 : -1) *
                                userIndex; 
                              return (
                                <React.Fragment key={user.id}>
                                  <Marker
                                    position={[
                                      parseFloat(user.lat) + offsetLat,
                                      parseFloat(user.lng) + offsetLng,
                                    ]}
                                    icon={createUserIcon(
                                      userColor,
                                      userIndex + 1
                                    )}
                                    eventHandlers={{
                                      click: () => {},
                                    }}
                                  >
                                    <Popup>
                                      <div>
                                        <strong>{user.name}</strong>
                                      </div>
                                    </Popup>
                                  </Marker>
                                  <Polyline
                                    pathOptions={{
                                      color: userColor,
                                      weight: 1.5,
                                      opacity: 0.5,
                                    }}
                                    positions={user.pastLocations.map(
                                      (location) => [
                                        parseFloat(location.lat),
                                        parseFloat(location.lng),
                                      ]
                                    )}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </MapContainer>
                  </div>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default TrackLocation;
