/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect, useRef } from "react";
import HeaderLayout from "../../layouts/admin/HeaderLayout";
import FooterLayout from "../../layouts/admin/FooterLayout";
import Listfilter from "../../components/ListFilter";
import MaterialDataGrid from "../../components/DataTable";
import { axiosInstance } from "../../utils/commonInstance";
import useDataFetching from "../../hooks/useDataFatching";
import { Box, IconButton, Modal, Typography } from "@mui/material";

import { handleExportData } from "../../components/DataTable";
import { useForm, Controller } from "react-hook-form";
import { showToast } from "../../hooks/showToast";
import Multiselect from "multiselect-react-dropdown";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import * as XLSX from "xlsx";

import { AltRoute, Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import {
  addSerialNumber,
  changeArrayKeys,
  createHeaderMap,
  extractKeysFromArray,
  flattenObject1,
  flattenObjectForReportsPage,
  transformData,
} from "../../utils/commonFunction";
import { Speaker } from "feather-icons-react";

const fetchDataForPlatform = async (params, endpoint) => {
  return await axiosInstance.post(`web/${endpoint}`, { params });
};

const CommonReportsList = ({
  title,
  dynamiColumns,
  endPoint,
  columnOrder,
  excludeKeys,
  exportColumns,
  keyMapping,
  toggleFormFillrter,
  filterOption,
  isExport = false,
  data,
}) => {
  const location = useLocation();
  const {
    state,
    columnFilters,
    setColumnFilters,
    setGlobalFilter,
    // pagination,
    // setPagination,
    manualFetchData,
  } = useDataFetching(fetchDataForPlatform, endPoint, title);
  const [calculations, setCalculations] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const [modal, setModal] = useState({
    isOpen: false,
    headerContent: "",
    subHeaderContent: "",
    data: {},
    clicked: null,
    text: "",
  });

  const handlePreviewClick = (row) => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
      header: "Image Preview",
      data: row,
    }));
    // Add your edit logic here
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
      header: "",
      data: {},
    }));
  };

  useEffect(() => {
    // Assuming OpenNewPagination is an object with properties pageIndex and pageSize
    const calculation = pagination.pageIndex * pagination.pageSize;
    // Set test to 0 if OpenNewPagination.pageIndex is 0, otherwise use the calculation
    setCalculations(calculation);
  }, [pagination.pageIndex, pagination.pageSize]);

  const dynamicColumns = useMemo(() => {
    const columns = [];
    // Add dynamic columns
    columns.push({
      header: "Sr. No.",
      Cell: ({ row }) => {
        const number = calculations + (row?.index + 1);
        const formattedNumber = number < 10 ? `0${number}` : number;
        return <div className="sr-no">{formattedNumber}</div>;
      },
      maxSize: 15,
      minSize: 10,
    });

    dynamiColumns?.forEach((col) => {
      if (col.accessorKey === "preview") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => (
            <div onClick={() => handlePreviewClick(row?.original)}>
              <a role="button" className="text-decoration-underline">
                Preview
              </a>
            </div>
          ),
        });
      } else if (col.accessorKey === "map") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => (
            <div onClick={() => handlePreviewClick(row?.original)}>
              <a role="button" className="text-decoration-underline">
                Map
              </a>
            </div>
          ),
        });
      } else if (col.accessorKey === "voicenote") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => {
            // Extract the relevant data from row.original
            const recordingId = row?.original?.alert_audio_url; // or whatever key holds the dynamic part

            return (
              <a
                href={recordingId}
                className="text-decoration-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Speaker />
              </a>
            );
          },
        });
      } else {
        columns.push(col);
      }
    });
    return columns;
  }, [dynamiColumns, calculations]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    height: "500px",
    overflow: "auto",
    "@media (max-width: 600px)": {
      width: "96%",
    },
  };

  // Define a function to remove specific items from the array
  function removeItems(array, itemsToRemove) {
    return array.filter((item) => !itemsToRemove.includes(item));
  }

  const finalArray = extractKeysFromArray(
    state?.data,
    removeItems(columnOrder, excludeKeys)
  );
  const newArray = changeArrayKeys(finalArray, keyMapping);

  newArray &&
    newArray?.forEach((obj) => {
      delete obj.Shift;
    });

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSelectedCompanyOptions([]);
    setSelectedLocationOptions([]);
    setSelectedSecurityGuardOptions([]);
    setLocationOption([]);
    setSecurityGuardOption([]);
    
  }, [endPoint, title]);

  const [fillter, setFillter] = useState({ selectFillter: "", search: "" });
  const [selectFilter, setSelectFilter] = useState(null);
  const [roleRightsData, setRoleRightsData] = useState([]);
  const [value, onChange] = useState([]);
  //   const [value, onChange] = useState([
  //     new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  //     new Date(),
  //   ]);

  const multiselectRef = React.createRef();
  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [vendorOption, setVendorOption] = useState([]);
  const [securityGuardOption, setSecurityGuardOption] = useState([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
  const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
  const [selectedVendorOptions, setSelectedVendorOptions] = useState([]);
  const [selectedSecurityGuardOptions, setSelectedSecurityGuardOptions] =
    useState([]);
  const [checkInCheckOut, setCheckInCheckOut] = useState("");
  const StartDate = moment(value[0]).format("YYYY-MM-DD");
  const EndDate = moment(value[1]).format("YYYY-MM-DD");

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({});

  const roleId = localStorage.getItem("role_id");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `web/getrolerightsdata/${roleId}`
        );
        const rightsData = response?.data?.data || [];
        setRoleRightsData(rightsData);
        rightsData.forEach((right) => {
          setValue(right, true);
        });
      } catch (error) {
        showToast("Error fetching role rights data", "error");
      }
    }
    if (roleId) {
      fetchData();
    }
  }, [roleId, setValue]);

  const pagePermissions = {
    "/userlist": ["user.add"],
    "/companymasterlist": ["company.add"],
    "/locationList": ["location.add"],
    "/companylist": ["companylocation.add"],
    "/vendorlist": ["vendor.add"],
    "/shiftlist": ["shift.add"],
    "/designationlist": ["designation.add"],
    "/checkpointlist": ["checkpoint.add"],
    "/roleList": ["role.add"],
    "/apkConfiguration": ["appconfig.add"],
    "/attendanceReports": ["attendance.export"],
    "/getfire": ["firealertreport.export"],
    "/getLockReports": ["gatelockreport.export"],
    "/getTrespassing": ["trespassingreport.export"],
    "/getpanicmode": ["panicmodereport.export"],
    "/getsalaryreport": ["salaryreport.export"],
  };
  const checkPermission = (roleRightsData, permission) =>
    !roleRightsData.includes(permission);
  const currentPath = location.pathname;
  const requiredPermissions = pagePermissions[currentPath] || [];

  const [gridData, setGridData] = useState({
    data: [],
    rowCount: 0,
    isError: false,
    isLoading: false,
    isRefetching: false,
  });

  const fetchData = async (isFiltered = false) => {
    try {
      setGridData((prev) => ({ ...prev, isLoading: true }));

      const url = `web/${endPoint}?limit=${pagination?.pageSize}&page=${
        pagination?.pageIndex + 1
      }`;

      const alertTypeMapping = {
        "/getfire": "fire",
        "/getTrespassing": "trespassing",
        "/getpanicmode": "panic mode",
      };

      let requestData = {};

      if (isFiltered) {
        if (!selectedCompanyOptions?.length) {
          showToast("Please Select Company Name", "error");
          return;
        }
        if (!selectedLocationOptions?.length) {
          showToast("Please Select Location", "error");
          return;
        }
        if (!selectedSecurityGuardOptions?.length) {
          showToast("Please Select Security Guard", "error");
          return;
        }
        if (!value.length || !value[0] || !value[1]) {
          showToast("Please Select Date", "error");
          return;
        }

        const startDate = moment(value[0], "DD-MM-YYYY", true);
        const endDate = moment(value[1], "DD-MM-YYYY", true);

        if (!startDate.isValid() || !endDate.isValid()) {
          showToast("Invalid date format. Use DD-MM-YYYY", "error");
          return;
        }

        if (
          startDate.year().toString().length !== 4 ||
          endDate.year().toString().length !== 4
        ) {
          showToast("Year must be 4 digits (DD-MM-YYYY)", "error");
          return;
        }

        requestData = {
          company_id: changeCompanyData,
          locationId: changeLocationData,
          userId: selectedSecurityGuardOptions.map((item) => item.id),
          from: StartDate,
          to: EndDate,
          ...(endPoint === "getNotificationsAllReport"
            ? { alert_type: alertTypeMapping[location?.pathname] }
            : endPoint === "attendance" && checkInCheckOut !== ""
            ? { attendance: checkInCheckOut }
            : {}),
        };
      } else {
        requestData =
          endPoint === "getNotificationsAllReport"
            ? { alert_type: alertTypeMapping[location?.pathname] }
            : {};
      }

      const response = await axiosInstance.post(url, requestData);

      if (!response?.data?.error) {
        setGridData({
          data: response?.data?.data,
          rowCount: response.data.totalRecords || response.totalRecords,
          isError: false,
          isLoading: false,
          isRefetching: false,
        });
      } else {
        showToast(response?.data?.message, "error");
        setGridData((prev) => ({ ...prev, isError: true, isLoading: false }));
      }
    } catch (error) {
      showToast("Error fetching data", "error");
      setGridData((prev) => ({ ...prev, isError: true, isLoading: false }));
    }
  };

  useEffect(() => {
    if (
      !selectedCompanyOptions?.length &&
      !selectedLocationOptions?.length &&
      !selectedSecurityGuardOptions?.length
    ) {
      fetchData(false);
    }
  }, [pagination, endPoint, title, selectedCompanyOptions]);

  const handleFilterSubmit = async () => {
    await fetchData(true);
  };

  useEffect(() => {
    if (
      selectedCompanyOptions?.length > 0 ||
      selectedLocationOptions?.length > 0 ||
      selectedSecurityGuardOptions?.length > 0
    ) {
      fetchData(true);
    }
  }, [pagination]);

  useEffect(() => {
    async function fetchCompany() {
      const response = await axiosInstance.get(`web/companyallfilter`);
      if (!response?.data?.error) {
        const formattedOptions = response?.data?.data?.map((ele) => ({
          id: ele?.id,
          name: ele?.company_name,
        }));
        setCompanyOption(formattedOptions);
      } else {
        setCompanyOption([]);
        // showToast(response?.data?.message, "error");
      }
    }
    fetchCompany();
  }, []);

  const changeCompanyData = watch("company_id");
  const changeLocationData = watch("location_id");
  useEffect(() => {
    if (changeCompanyData) {
      if (changeCompanyData?.length === 0) {
        setSelectedLocationOptions([]);
        setSelectedVendorOptions([]);
        setSelectedSecurityGuardOptions([]);
      }

      async function fetchLocations() {
        const params = {
          company_id: changeCompanyData,
        };
        // console.log(params);
        try {
          const response = await axiosInstance.post(`web/locationallfilter`, {
            ...params,
            mode_of_login: "Web",
          });
          if (response?.data?.statusCode !== 200) {
            setLocationOption([]);
            return false;
          }
          const formattedOptions = response?.data?.data?.map((ele) => ({
            id: ele?.id,
            name: ele?.name,
          }));
          setLocationOption(formattedOptions);
        } catch (error) {
          //   showToast(error?.data?.message, "error");
        }
      }
      fetchLocations();
    }

    if (changeLocationData) {
      if (changeLocationData?.length === 0) {
        setSelectedSecurityGuardOptions([]);
      }
      async function fetchSecurityGuards() {
        const params = {
          company_id: changeCompanyData,
          locationId: changeLocationData,
        };
        // console.log(params);
        try {
          const response = await axiosInstance.post(`web/userallfilter`, {
            ...params,
            mode_of_login: "Web",
          });
          if (response?.data?.statusCode !== 200) {
            setSecurityGuardOption([]);
            return false;
          }
          const formattedOptions = response?.data?.data?.map((ele) => ({
            id: ele?.id,
            name: ele?.username,
          }));
          setSecurityGuardOption(formattedOptions);
        } catch (error) {
          //   showToast(error?.data?.message, "error");
        }
      }
      fetchSecurityGuards();
    }
  }, [changeCompanyData, changeLocationData]);

  const handleSelect = (
    selectedList,
    selectedItem,
    optionList,
    setSelectedOptions,
    setFieldValue,
    fieldName
  ) => {
    if (selectedItem?.id === "All") {
      const ids = optionList.map((option) => option.id);
      setSelectedOptions(optionList);
      setFieldValue(fieldName, ids);
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        const ids = newList.map((option) => option.id);
        setSelectedOptions(newList);
        setFieldValue(fieldName, ids);
      } else {
        if (optionList?.length - 1 === selectedList?.length) {
          const ids = optionList.map((option) => option.id);
          setSelectedOptions(optionList);
          setFieldValue(fieldName, ids);
        } else {
          const ids = selectedList.map((option) => option.id);
          setSelectedOptions(selectedList);
          setFieldValue(fieldName, ids);
        }
      }
    }
  };

  const handleRemove = (
    selectedList,
    removedItem,
    setSelectedOptions,
    setFieldValue,
    fieldName
  ) => {
    // Remove "All" from the selected list if any individual item is removed
    let updatedList = selectedList.filter((item) => item.id !== "All");

    // Extract updated IDs
    const ids = updatedList.map((option) => option.id);

    setSelectedOptions(updatedList);
    setFieldValue(fieldName, ids);

    // If the removed item was "All" or the last item, clear everything
    if (removedItem?.id === "All" || updatedList.length === 0) {
      setSelectedOptions([]);
      setFieldValue(fieldName, []);
    }

    // **Clear dependent selections based on removed field**
    if (fieldName === "company_id") {
      setSelectedLocationOptions([]);
      setSelectedSecurityGuardOptions([]);
      setValue("location_id", []);
      setValue("security_guard_id", []);
    } else if (fieldName === "location_id") {
      setSelectedSecurityGuardOptions([]);
      setValue("security_guard_id", []);
    }
  };

  const handleExport = () => {
    if (!gridData?.data?.length) {
      showToast("No data available for export", "error");
      return;
    }

    const reportMappings = {
      attendance: (row) => ({
        "Sr. No.": calculations + (row.index + 1),
        // "Guard Code": row.guard_code,
        "Guard Name (Guard Code)": row.username,
        "Company Name": row.company_name,
        Location: row.location_name,
        Designation: row.designation_name,
        "Check-in": row.checkin_time,
        "Check-out": row.checkout_time,
        "Worked hours": row.worked_hours,
        "Away Time": row.total_in_out_duration,
        "Assigned Checkpoint": row.checkpoints_name,
        Shift: row.shift_name,
        Date: row.created_at,
      }),
      gatelockreport: (row) => ({
        "Sr. No.": calculations + (row.index + 1),
        "Guard Name (Guard Code)": row.username,
        "Company Name": row.company_name,
        Location: row.location_name,
        Designation: row.designation_name,
        "Gate Close": row.gate_close_time,
        "Other Guard on Duty": row.other_guard_name,
        "Assigned Checkpoint": row.checkpoint,
        Date: row.created_at,
      }),
      getNotificationsAllReport: (row) => ({
        "Sr. No.": calculations + (row.index + 1),
        "Guard Name (Guard Code)": row.username,
        "Company Name": row.company_name,
        Location: row.location_name,
        Designation: row.designation_name,
        Time: row.alert_created_time,
        Date: row.alert_created_date,
        Note: row.alert_message,
      }),
    };

    // Get the data transformation function based on endPoint
    const transformRow = reportMappings[endPoint];
    if (!transformRow) {
      showToast("Invalid report type", "error");
      return;
    }

    // Process and map data
    const exportData = gridData.data.map((row, index) =>
      transformRow({ ...row, index })
    );

    // Generate Excel
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");

    // Determine file name dynamically
    const reportNames = {
      "/getfire": "Fire Alert",
      "/getTrespassing": "Trespassing Alert",
      "/getpanicmode": "Panic Mode",
      attendance: "Attendance",
      gatelockreport: "Gate Lock",
    };

    const reportName = reportNames[location?.pathname] || reportNames[endPoint];
    XLSX.writeFile(workbook, `${reportName} Report.xlsx`);
  };

  useEffect(() => {
    setValue("checkIn-checkOut", "");
    setCheckInCheckOut("");
    onChange([]);
  }, [location.pathname, setValue, setCheckInCheckOut]);

  return (
    <>
      <div className="main">
        <HeaderLayout title={title} />
        <section class="user-listmain content web">
          <>
            <div>
              <div class="mb-4 row gy-2">
                <div class="col-xxl-3 col-xl-4 col-sm-6">
                  <Multiselect
                    key={location.pathname}
                    ref={multiselectRef}
                    options={companyOption}
                    selectedValues={selectedCompanyOptions}
                    displayValue="name"
                    placeholder={"Company"}
                    defaultValue=""
                    onSelect={(selectedList, selectedItem) =>
                      handleSelect(
                        selectedList,
                        selectedItem,
                        companyOption,
                        setSelectedCompanyOptions,
                        setValue,
                        "company_id"
                      )
                    }
                    onRemove={(selectedList, removedItem) =>
                      handleRemove(
                        selectedList,
                        removedItem,
                        setSelectedCompanyOptions,
                        setValue,
                        "company_id"
                      )
                    }
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={true}
                    showCheckbox={true}
                    name="company_id"
                    id="company_id"
                    {...register("company_id")}
                    style={{
                      searchBox: {
                        height:
                          selectedCompanyOptions?.length >= 3
                            ? "100px"
                            : "40px",
                        overflowY:
                          selectedCompanyOptions?.length >= 3
                            ? "scroll"
                            : "auto",
                      },
                    }}
                  />
                </div>
                <div class="col-xxl-3 col-xl-4 col-sm-6">
                  <Multiselect
                    ref={multiselectRef}
                    options={locationOption}
                    selectedValues={selectedLocationOptions}
                    displayValue="name"
                    placeholder={"Location"}
                    defaultValue=""
                    onSelect={(selectedList, selectedItem) =>
                      handleSelect(
                        selectedList,
                        selectedItem,
                        locationOption,
                        setSelectedLocationOptions,
                        setValue,
                        "location_id"
                      )
                    }
                    onRemove={(selectedList, removedItem) =>
                      handleRemove(
                        selectedList,
                        removedItem,
                        setSelectedLocationOptions,
                        setValue,
                        "location_id"
                      )
                    }
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={true}
                    showCheckbox={true}
                    name="location_id"
                    id="location_id"
                    {...register("location_id")}
                    style={{
                      searchBox: {
                        height:
                          selectedLocationOptions?.length >= 3
                            ? "100px"
                            : "40px",
                        overflowY:
                          selectedLocationOptions?.length >= 3
                            ? "scroll"
                            : "auto",
                      },
                    }}
                  />
                </div>
                <div class="col-xxl-3 col-xl-4 col-sm-6">
                  <Multiselect
                    ref={multiselectRef}
                    options={securityGuardOption}
                    selectedValues={selectedSecurityGuardOptions}
                    displayValue="name"
                    placeholder={"Security Guard"}
                    defaultValue=""
                    onSelect={(selectedList, selectedItem) =>
                      handleSelect(
                        selectedList,
                        selectedItem,
                        securityGuardOption,
                        setSelectedSecurityGuardOptions,
                        setValue,
                        "security_guard_id"
                      )
                    }
                    onRemove={(selectedList, removedItem) =>
                      handleRemove(
                        selectedList,
                        removedItem,
                        setSelectedSecurityGuardOptions,
                        setValue,
                        "security_guard_id"
                      )
                    }
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={true}
                    showCheckbox={true}
                    name="security_guard_id"
                    id="security_guard_id"
                    {...register("security_guard_id")}
                    style={{
                      searchBox: {
                        height:
                          selectedSecurityGuardOptions?.length >= 3
                            ? "100px"
                            : "40px",
                        overflowY:
                          selectedSecurityGuardOptions?.length >= 3
                            ? "scroll"
                            : "auto",
                      },
                    }}
                  />
                </div>
                {endPoint === "attendance" && (
                  <div class="col-xxl-3 col-xl-4 col-sm-6">
                    <div className="form-group">
                      <Controller
                        name="checkIn-checkOut"
                        control={control}
                        render={({ field }) => (
                          <select
                            className="form-select py-2 w-100"
                            {...field}
                            defaultValue={checkInCheckOut}
                            onChange={(e) => {
                              field.onChange(e);
                              setCheckInCheckOut(e.target.value);
                            }}
                            style={{ backgroundColor: "#f5f7fb" }}
                          >
                            <option value="" selected>
                              Checked In / Checked Out
                            </option>
                            <option value="CheckIn">Checked In</option>
                            <option value="CheckOut">Checked Out</option>
                          </select>
                        )}
                      />
                    </div>
                  </div>
                )}
                <div class="col-xxl-3 col-xl-4 col-sm-6">
                  <DateRangePicker
                    maxDate={new Date()}
                    disableClock
                    value={value}
                    onChange={onChange}
                    format={"dd/MM/y"}
                    clearIcon={null}
                    className="w-100"
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                  />
                </div>
                <div
                  class={`${
                    endPoint === "attendance"
                      ? "col-xxl-3 col-xl-4 col-sm-6"
                      : "col-xxl-12 col-xl-4 col-sm-12"
                  } text-end`}
                >
                  <button
                    onClick={handleFilterSubmit}
                    class="btn btn-lg btn-primary px-3 h-100 me-2"
                  >
                    Apply Filter
                  </button>
                  <button
                    onClick={handleExport}
                    // onClick={() => {
                    //   if (data?.data?.length) handleExportData(data?.data);
                    // }}
                    class="btn btn-lg btn-primary px-3 h-100"
                    disabled={requiredPermissions.some((permission) =>
                      checkPermission(roleRightsData, permission)
                    )}
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
            <MaterialDataGrid
              key={location.pathname}
              columns={dynamicColumns}
              //   data={gridData.data?.data}
              data={gridData.data}
              rowCount={gridData.rowCount}
              isError={gridData.isError}
              handleColumnFilter={setColumnFilters}
              handlePagination={setPagination}
              initialState={{
                columnFilters: columnFilters,
                isLoading: gridData.isLoading,
                pagination: pagination,
                showAlertBanner: gridData.isError,
                showProgressBars: gridData.isRefetching,
                columnOrder: columnOrder ? columnOrder : [],
              }}
            />
          </>
        </section>
        <FooterLayout />
        <Modal
          open={modal?.isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ overflow: "hidden" }}>
            <Box sx={style}>
              <IconButton
                sx={{ position: "absolute", top: 8, right: 8 }}
                className="text-white p-2 bg-dark"
                onClick={handleClose}
              >
                <Close />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal?.header}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {location?.pathname === "/attendanceReports" ? (
                  <>
                    <div className="row">
                      <div className="col d-flex justify-content-center align-items-center flex-column">
                        <label className="mb-2">Check-In Image:</label>
                        <img
                          src={modal.data?.checkin_img_name}
                          width="300"
                          height="300"
                          alt="No Image Found"
                          loading="lazy"
                          rel="noopener noreferrer"
                          className="img-fluid"
                        />
                      </div>

                      <div class="col d-flex justify-content-center align-items-center flex-column">
                        <label className="mb-2">Check-Out Image:</label>
                        <img
                          src={modal.data?.checkout_img_name}
                          width="300"
                          height="300"
                          alt="No Image Found"
                          loading="lazy"
                          rel="noopener noreferrer"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {modal.data?.guard_img_url ? (
                      <>
                        <label>Guard Image:</label> <br />
                        <img
                          src={modal.data?.guard_img_url}
                          width="300"
                          height="300"
                          alt="Guard Image"
                          loading="lazy"
                          rel="noopener noreferrer"
                        />
                      </>
                    ) : (
                      <div className="image-grid">
                        {modal?.data?.checkpoint_data?.map((ele, index) => (
                          <div className="image-item" key={index}>
                            <label>{ele?.checkpoint_name}: </label> <br />
                            <img
                              src={ele?.checkpoint_img_url}
                              width="300"
                              height="300"
                              alt={ele?.checkpoint_name}
                              loading="lazy"
                              rel="noopener noreferrer"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <style jsx>{`
          .image-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
          }
          .image-item {
            text-align: center;
          }
          .row {
            display: flex;
            justify-content: space-between;
          }
          .col {
            flex: 1 0 45%;
            margin: 10px;
            text-align: center;
          }
        `}</style>
      </div>
    </>
  );
};
export default CommonReportsList;
